import Lineup from '@/classes/Lineup.js'
const getTeam = (id, tournament) => {
  return id ? tournament.teams.find(d => d.id === id) : null
}

export default function (form, tournament, division, round, match, homeTeam, awayTeam, pool) {
  awayTeam = match.awayTeam ? getTeam(match.awayTeam.teamId, tournament) : null
  homeTeam = match.homeTeam ? getTeam(match.homeTeam.teamId, tournament) : null

  const hl = homeTeam && match.lineups && match.lineups.find(f => f.teamId === homeTeam.id)
  const al = awayTeam && match.lineups && match.lineups.find(f => f.teamId === awayTeam.id)
  const homeLineup = hl && new Lineup(hl)
  const awayLineup = al && new Lineup(al)

  const base = {
    Teams1: null,
    TeamName1: null,
    Team1_Player1: null,
    Team1_Player2: null,
    TeamName2: null,
    Team2_Player2: null,
    Team2_Player1: null,
    title: tournament.name,
    division: division.name,
    round: pool ? `Pool ${pool.name}` : round.name,
    team1: null,
    team2: null,
    // match: null,
    ct: [match.court, match.time].join('\n')
  }

  const teamNames = []
  if (homeTeam) {
    teamNames.push(homeTeam.name)
    base.TeamName1 = homeTeam.name
    let t = homeTeam.name
    if (match.isDual) t += ` Pair ${match.dualMatchN}`
    const team1Array = [t]

    let pair = null
    if (match.isDual) {
      if (homeLineup) {
        const _hl = homeLineup.starters.length > 0 && homeLineup.starters[match.dualMatchN - 1]
        if (_hl) {
          const playerIds = _hl.players
          pair = {
            players: homeTeam.players.filter(f => playerIds.includes(f.id))
          }
        }
      }
    } else {
      pair = homeTeam
    }

    if (pair) {
      const p1 = pair.players[0]
      if (p1) {
        const p1A = [p1.firstName, p1.lastName]
        if (p1.jersey) p1A.push(`#${p1.jersey}`)
        base.Team1_Player1 = p1A.join(' ')
        team1Array.push(base.Team1_Player1)
      }

      const p2 = pair.players[1]
      if (p2) {
        const p2A = [p2.firstName, p2.lastName]
        if (p2.jeresy) p2A.push(`#${p2.jersey}`)
        base.Team1_Player2 = p2A.join(' ')
        team1Array.push(base.Team1_Player2)
      }
    }

    base.team1 = team1Array.join('\n')
  }

  if (awayTeam) {
    teamNames.push(awayTeam.name)
    base.TeamName2 = awayTeam.name
    let t = awayTeam.name
    if (match.isDual) t += ` Pair ${match.dualMatchN}`
    const team2Array = [t]

    let pair = null
    if (match.isDual) {
      if (awayLineup) {
        const _al = awayLineup.starters.length > 0 && awayLineup.starters[match.dualMatchN - 1]
        if (_al) {
          const playerIds = _al.players
          pair = {
            players: awayTeam.players.filter(f => playerIds.includes(f.id))
          }
        }
      }
    } else {
      pair = awayTeam
    }

    if (pair) {
      const p1 = pair.players[0]
      if (p1) {
        const p1A = [p1.firstName, p1.lastName]
        if (p1.jersey) p1A.push(`#${p1.jersey}`)
        base.Team2_Player1 = p1A.join(' ')
        team2Array.push(base.Team2_Player1)
      }

      const p2 = pair.players[1]
      if (p2) {
        const p2A = [p2.firstName, p2.lastName]
        if (p2.jersey) p2A.push(`#${p2.jersey}`)
        base.Team2_Player2 = p2A.join(' ')
        team2Array.push(base.Team2_Player2)
      }
    }

    base.team2 = team2Array.join('\n')
  }

  if (teamNames.length === 2) {
    base.Teams1 = `${teamNames[0]} vs ${teamNames[1]}`
  }

  const isPool = round.pools.length > 0
  if (isPool) {
    base.round = base.round + '\n' + (match.getDualDesc() || `Match ${match.number}`)
  } else {
    if (round.bracket) {
      base.round = base.round + '\n' + (match.getDualDesc(round.bracket) || `Match ${match.displayNumber} (${round.bracket.getMatchTitle(match)})`)
    }
  }

  for (const field in base) {
    const val = base[field]
    if (val) {
      // console.log(val)
      const ff = form.getTextField(field)
      ff.setText(val)
    }
  }

  return form
}
