<template>
  <v-btn
    color="color3 color3Text--text"
    :fab="fab"
    :small="small"
    :block="block"
    :loading="loading"
    :text="text"
    :x-small="xSmall"
    @click="onClick"
  >
    <v-icon v-if="fab">fas fa-print</v-icon>
    {{ fab ? null : 'Print Score Sheet' }}
  </v-btn>
</template>

<script>
import { PDFDocument } from 'pdf-lib'
import printJS from 'print-js'
import MapScoreSheet from '@/helpers/MapScoreSheet'

export default {
  props: ['fab', 'tournament', 'division', 'round', 'matches', 'pool', 'block', 'text', 'xSmall', 'small', 'bracket'],
  data () {
    return {
      pdfUrl: 'https://vblimages.blob.core.windows.net/files/0a0162c1-a7d4-46bc-bf99-673393096590.pdf',
      loading: false
    }
  },
  computed: {
  },
  methods: {
    async onClick () {
      console.log('print me')
      this.loading = true
      const formPdfBytes = await fetch(this.pdfUrl).then(res => res.arrayBuffer())
      const pdfDoc = await PDFDocument.create()

      for (const match of this.matches) {
        const page1Doc = await PDFDocument.load(formPdfBytes)
        const form = page1Doc.getForm()
        MapScoreSheet(form, this.tournament, this.division, this.round, match, this.pool)
        await page1Doc.save()
        const [page1] = await pdfDoc.copyPages(page1Doc, [0])
        pdfDoc.addPage(page1)
      }

      const pdfBytes = await pdfDoc.save()
      const file = new Blob([pdfBytes], { type: 'application/pdf' })
      this.loading = false
      // this.downloadFile(file, 'test.pdf')
      this.printFile(file)
    },
    downloadFile (blob, fileName) {
      const link = document.createElement('a')
      // create a blobURI pointing to our Blob
      link.href = URL.createObjectURL(blob)
      link.download = fileName
      // some browser needs the anchor to be in the doc
      document.body.append(link)
      link.click()
      link.remove()
      // in case the Blob uses a lot of memory
      setTimeout(() => URL.revokeObjectURL(link.href), 7000)
    },
    printFile (blob) {
      printJS(URL.createObjectURL(blob))
    }
  }
}
</script>
